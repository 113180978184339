import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form } from 'react-bootstrap';
import Select from "react-select";
import { navigate } from "@reach/router"
import { Link } from "@StarberryUtils";
import $ from "jquery";
import SearchInputBox from '../predictive-search/search-inputbox';

import _ from "lodash"

const SearchResultsFilterCommRentals = (props) => {
    // 
    const refineFilterResults = (e) => {
        $(".filter-toggle").toggleClass("active");
        $(".filters-form").toggleClass("active-filter");
    }
    // 

    // Sales & Rent
    const onClickBuy = (e) => {
        navigate(`/property/commercial/for-sale/in-london/`)
    }
    
    const onClickRent = (e) => {
        navigate(`/property/commercial/to-rent/in-london/`)
    }
    // Sales & Rent

    // Property Min Price
    const [selectedMinPriceValue, setSelectedMinPriceValue] = useState(0);
    const priceminRange = [
        {
            value: "0",
            label: "No Min"
          },
          {
            value: "100",
            label: "£100 pcm"
          },
          {
            value: "200",
            label: "£200 pcm"
          },
          {
            value: "300",
            label: "£300 pcm"
          },
          {
            value: "400",
            label: "£400 pcm"
          },
          {
            value: "500",
            label: "£500 pcm"
          },
          {
            value: "750",
            label: "£750 pcm"
          },
          {
            value: "1000",
            label: "£1,000 pcm"
          },
          {
            value: "2000",
            label: "£2,000 pcm"
          },
          {
            value: "3000",
            label: "£3,000 pcm"
          },
          {
            value: "4000",
            label: "£4,000 pcm"
          },
          {
            value: "5000",
            label: "£5,000 pcm"
          },
          {
            value: "10000",
            label: "£10,000 pcm"
          }
    ]
    // Property Min Price

    const propertytypelist = [
      { value: "", label: "All Properties" },
      { value: "shop", label: "Shop" },
      { value: "food-premises", label: "Food Premises" },
      { value: "office", label: "Office" },
      { value: "industrial", label: "Industrial/Storage" },
      { value: "upperpart", label: "Shop & Upper Part" },
      { value: "retail", label: "Retail Office" },
      { value: "land", label: "Land/Development" },
      { value: "community-premises", label: "Community Premises" },
  ]

    // Property Max Price
    const [selectedMaxPriceValue, setSelectedMaxPriceValue] = useState(0);
    const pricemaxRange = [
        {
            value: "0",
            label: "No Max"
        },
        {
          value: "10000",
          label: "£10,000 pcm +"
        },
        {
          value: "5000",
          label: "£5,000 pcm"
        },
        {
          value: "4000",
          label: "£4,000 pcm"
        },
        {
          value: "3000",
          label: "£3,000 pcm"
        },
        {
          value: "2000",
          label: "£2,000 pcm"
        },
        {
          value: "1000",
          label: "£1,000 pcm"
        },
        {
          value: "750",
          label: "£750 pcm"
        },
        {
          value: "500",
          label: "£500 pcm"
        },
        {
          value: "400",
          label: "£400 pcm"
        },
        {
          value: "300",
          label: "£300 pcm"
        },
        {
          value: "200",
          label: "£200 pcm"
        },
        {
          value: "100",
          label: "£100 pcm"
        }
    ]
    // Property Max Price

    // Property Min sqft
    const [selectedMinSqftValue, setSelectedMinSqftValue] = useState(0);
    const sqftminRange = [
        {
          value: "0",
          label: "No Min"
        },
        {
          value: "500",
          label: "500 Sqft"
        },
        {
          value: "1000",
          label: "1000 Sqft"
        },
        {
          value: "1500",
          label: "1500 Sqft"
        },
        {
          value: "2000",
          label: "2000 Sqft"
        },
        {
          value: "3000",
          label: "3000 Sqft"
        },
        {
          value: "4000",
          label: "4000 Sqft"
        },
        {
          value: "5000",
          label: "5000 Sqft"
        },
        {
          value: "10000",
          label: "10000 Sqft"
        },
        {
          value: "15000",
          label: "15000 Sqft"
        },
        {
          value: "20000",
          label: "20000 Sqft +"
        }
    ]
    // Property Min sqft

    // Property Max sqft
    const [selectedMaxSqftValue, setSelectedMaxSqftValue] = useState(0);
    const sqftmaxRange = [
        {
          value: "0",
          label: "No Max"
        },
        {
          value: "20000",
          label: "20000 Sqft +"
        },
        {
          value: "15000",
          label: "15000 Sqft"
        },
        {
          value: "10000",
          label: "10000 Sqft"
        },
        {
          value: "5000",
          label: "5000 Sqft"
        },
        {
          value: "4000",
          label: "4000 Sqft"
        },
        {
          value: "3000",
          label: "3000 Sqft"
        },
        {
          value: "2000",
          label: "2000 Sqft"
        },
        {
          value: "1500",
          label: "1500 Sqft"
        },
        {
          value: "1000",
          label: "1000 Sqft"
        },
        {
          value: "500",
          label: "500 Sqft"
        }
    ]
    // Property Max sqft

    // Min price
    const onChangeMinPrice = (e) => {
        setSelectedMinPriceValue(e.value);
    }
    // Min price

    // Max Price
    const onChangeMaxPrice = (e) => {
        setSelectedMaxPriceValue(e.value);
    }
    // Max Price

    // Min Sqft
    const onChangeMinSqft = (e) => {
        setSelectedMinSqftValue(e.value);
    }
    // Min Sqft

    // Max Sqft
    const onChangeMaxSqft = (e) => {
        setSelectedMaxSqftValue(e.value);
    }
    // Max Sqft

    // Price
    var minPrice = [];
    var maxPrice = pricemaxRange.filter(c => (c.value === "0" || parseInt(c.value) > parseInt(selectedMinPriceValue)));
  
    priceminRange.map(items => parseInt(items.value) < parseInt(selectedMaxPriceValue) && minPrice.push(items));
    // pricemaxRange.map(items => parseInt(items.value) > parseInt(selectedMinPriceValue) && maxPrice.push(items));

    if (minPrice.length > 0) {
    } else {
      minPrice.push(priceminRange[0])
    }

    if (maxPrice.length > 0) {
    } else {
      maxPrice.push(pricemaxRange[0])
    }
    // Price

    // Sqft
    var minSqft = [];
    var maxSqft = [];
  
    sqftminRange.map(items => parseInt(items.value) < parseInt(selectedMaxSqftValue) && minSqft.push(items));
    sqftmaxRange.map(items => parseInt(items.value) > parseInt(selectedMinSqftValue) && maxSqft.push(items));

    if (minSqft.length > 0) {
    } else {
      minSqft.push(sqftminRange[0])
    }

    if (maxSqft.length > 0) {
    } else {
      maxSqft.push(sqftmaxRange[0])
    }
    // Sqft
      
    const [areaVal, setAreaVal] = useState('');
    const [minpriceVal, setMinPriceVal] = useState('');
    const [maxpriceVal, setMaxPriceVal] = useState('');
    const [propertyval, setPropertyVal] = useState('');

    const [minsqft1, setMinSqFt1] = useState('');
    const [maxsqft1, setMaxSqFt1] = useState('');

    const [bedVal, setBedVal] = useState('');
    var pageVal = ""
    var sortVal = ""
    var sortVal_filt = ""
    // Submit search
    const submitSearch = (e) => {
        // 
        var areaValue = $(".searchboxresults .react-autosuggest__input").val().split(', ').join('-').replace(/ /g,"-").replace(/'/g,"").toLowerCase();
        var buildingValue = propertyval ? propertyval : $(".building-dropdown input[type='hidden']").val();
        // var sortbyValue = $(".sortby-dropdown input[type='hidden']").val();
        // var sortbyValue = $(".sortby-dropdown .select-opt__single-value").text().replace(/ /g, '-').toLowerCase();

        // let sortybyurl = ""
        // if (sortbyValue === "highest-price-first") {
        //   sortybyurl = "sortby-price-desc"
        // } else if (sortbyValue === "lowest-price-first") {
        //   sortybyurl = "sortby-price-asc"
        // } else if (sortbyValue === "most-recent-first") {
        //   sortybyurl = "sortby-most-recent"
        // }

        let priceUrl = ""
        if(maxpriceVal || minpriceVal) {
          if(minpriceVal && maxpriceVal && minpriceVal != "0" && maxpriceVal != "0") {
            priceUrl = "between-" + minpriceVal + "-and-" + maxpriceVal
          } else
          if((minpriceVal == '' || minpriceVal == "0") && maxpriceVal && maxpriceVal != "0") {
            priceUrl = "under-" + maxpriceVal
          }
          else if(minpriceVal && (maxpriceVal == '' || maxpriceVal == "0") && minpriceVal != "0") {
            priceUrl = "over-" + minpriceVal
          }

        }


        let sqftUrl = ""
        if(minsqft1 || maxsqft1) {
          if(minsqft1 && maxsqft1 && minsqft1 != "0" && maxsqft1 != "0") {
            sqftUrl = "sqft-" + minsqft1 + "-and-" + maxsqft1
          } else
          if((minsqft1 == '' || minsqft1 == "0") && maxsqft1 && maxsqft1 != "0") {
            sqftUrl = "maxsqft-" + maxsqft1
          }
          else if(minsqft1 && (maxsqft1 == '' || maxsqft1 == "0") && minsqft1 != "0") {
            sqftUrl = "minsqft-" + minsqft1
          }

        }
        // 
    
        var searchFilterResults = (
            (areaValue ? "/property/commercial/to-rent/in-"+areaValue +"/" : "/property/commercial/to-rent/in-london/") +
            (buildingValue ? "type-"+buildingValue+"/" : '') +
            (priceUrl ? priceUrl+"/" : '') +
            (sqftUrl ? sqftUrl+"/" : '')
            // (sortybyurl ? sortybyurl : '')
        );

        navigate(searchFilterResults)
    }
    // Submit search

    //

    useEffect(() => {

      const url = typeof window !== 'undefined' ? window.location.href : ''
      let pathUri = url.split("/")
      for (let vi = 1; vi <= pathUri.length; vi++) {
        // check for area
        if (typeof pathUri[vi] === "undefined") {
            continue
        }

        // Property type
        if (pathUri[vi].indexOf("type-") >= 0) {
          setPropertyVal(pathUri[vi].replace("type-", ""))
        }
        // Property type

        // Area
        if ((pathUri[vi].indexOf("in-") == 0) && (pathUri[vi].indexOf("in-london") == -1)) {
          setAreaVal(_.upperFirst(pathUri[vi].replace("in-", "")))
        }
        // Area
      
        // Price
          if (
            pathUri[vi].indexOf("between-") >= 0 ||
            pathUri[vi].indexOf("over-") >= 0 ||
            pathUri[vi].indexOf("under-") >= 0
        ) {
            let priceFilt1 = pathUri[vi].split("over-")
            if (priceFilt1[1]) {
              setMinPriceVal(priceFilt1[1])
            }
            let priceFilt2 = pathUri[vi].split("under-")
            if (priceFilt2[1]) {
              setMaxPriceVal(priceFilt2[1])
            }
            let priceFilt3 = pathUri[vi].split("between-")
            if (priceFilt3[1]) {
            let priceFilt4 = priceFilt3[1].split("-and-")
              setMinPriceVal(priceFilt4[0])
              setMaxPriceVal(priceFilt4[1])
            }
        }
        // Price


        
        // Price
        if (
          pathUri[vi].indexOf("sqft-") >= 0 ||
          pathUri[vi].indexOf("maxsqft-") >= 0 ||
          pathUri[vi].indexOf("minsqft-") >= 0
      ) {
          let priceFilt1 = pathUri[vi].split("maxsqft-")
          if (priceFilt1[1]) {
            setMinSqFt1(priceFilt1[1])
          }
          let priceFilt2 = pathUri[vi].split("minsqft-")
          if (priceFilt2[1]) {
            setMaxSqFt1(priceFilt2[1])
          }
          let priceFilt3 = pathUri[vi].split("sqft-")
          if (priceFilt3[1]) {
          let priceFilt4 = priceFilt3[1].split("-and-")
          setMinSqFt1(priceFilt4[0])
          setMaxSqFt1(priceFilt4[1])
          }
      }
      // Price
      


      }
    }, []);
    //
    return (
        <>
            <Link className={"filter-toggle d-flex justify-content-between align-items-center d-xl-none text-uppercase"} to="#" onClick={refineFilterResults}>
                <span>refine your results</span>
                <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.98403 8.00005L0.960028 15.04C0.752028 15.248 0.400028 15.248 0.176028 15.04C-0.0319716 14.832 -0.0319716 14.48 0.176028 14.256L6.43203 8.00005L0.160028 1.72805C-0.0479717 1.52005 -0.0479717 1.16805 0.160028 0.944047C0.368028 0.736047 0.720028 0.736047 0.944028 0.944047L7.98403 8.00005Z" fill="#ffffff"/>
                </svg>
            </Link>
            <Form  className={"filters-form d-xl-flex justify-content-between"}>
                <Form.Group className="radios-wrp">
                <div className="radio-boxes d-flex">
                    <a href="javascript:void(0)" className="property_filter_btn" onClick={onClickBuy}>Buy</a>
                    <a href="javascript:void(0)" className="property_filter_btn active" onClick={onClickRent}>Rent</a>
                </div>
                </Form.Group>
                <div className="searchboxresults d-flex align-items-center">
                    <SearchInputBox handleChange={(val)=> setAreaVal(val)} value={areaVal}/>
                </div>
                <Form.Group className="price-wrap d-flex justify-content-between align-items-center">
                    <div className="price-box">
                        <div className="select-group minprice-dropdown">
                            <Select
                                placeholder={minpriceVal ? minpriceVal : "Min Price" }
                                options={parseInt(selectedMaxPriceValue) === 0 ? priceminRange : minPrice}
                                value={priceminRange.find(obj => obj.value === minpriceVal) || priceminRange.find(obj => obj.value === minpriceVal)}
                                onChange={e => {onChangeMinPrice(e);setMinPriceVal(e.value)}}
                                classNamePrefix={"select-opt"}
                                isSearchable={false}
                                components={{ DropdownIndicator:() => <i className="icon icon-dropdown-arrow"></i>, IndicatorSeparator:() => null }}
                            />
                        </div>
                    </div>
                    <div className="price-box">
                        <div className="select-group maxprice-dropdown">
                            <Select
                                placeholder={maxpriceVal ? maxpriceVal : "Max Price" }
                                options={parseInt(selectedMinPriceValue) === 0 ? pricemaxRange : maxPrice}
                                value={pricemaxRange.find(obj => obj.value === selectedMaxPriceValue) || pricemaxRange.find(obj => obj.value === maxpriceVal)}
                                onChange={e => {onChangeMaxPrice(e);setMaxPriceVal(e.value)}}
                                classNamePrefix={"select-opt"}
                                isSearchable={false}
                                components={{ DropdownIndicator:() => <i className="icon icon-dropdown-arrow"></i>, IndicatorSeparator:() => null }}
                            />
                        </div>
                    </div>
                </Form.Group>
                <Form.Group className="select-grp-opt d-flex align-items-center">
                    <div className="select-group building-dropdown">
                        <Select
                            name="building"
                            options={propertytypelist}
                            value={propertytypelist.find(obj => obj.value === propertyval)}
                            placeholder={propertyval ? propertyval+" +" : "Property Type" }
                            classNamePrefix={"select-opt"}
                            isSearchable={false}
                            components={{ DropdownIndicator:() => <i className="icon icon-dropdown-arrow"></i>, IndicatorSeparator:() => null }}
                        />
                    </div>
                </Form.Group>

                <Form.Group className="price-wrap d-flex justify-content-between align-items-center">
                    <div className="price-box">
                        <div className="select-group minsqft-dropdown">
                            <Select
                                placeholder={minsqft1 ? minsqft1 : "Min Sqft" }
                                options={parseInt(selectedMaxSqftValue) === 0 ? sqftminRange : minSqft}
                                value={sqftminRange.find(obj => obj.value === selectedMinSqftValue) || sqftminRange.find(obj => obj.value === minsqft1)}
                                onChange={e => {onChangeMinSqft(e);setMinSqFt1(e.value)}}
                                classNamePrefix={"select-opt"}
                                isSearchable={false}
                                components={{ DropdownIndicator:() => <i className="icon icon-dropdown-arrow"></i>, IndicatorSeparator:() => null }}
                            />
                        </div>
                    </div>
                    <div className="price-box">
                        <div className="select-group maxsqft-dropdown">
                            <Select
                                placeholder={maxsqft1 ? maxsqft1 : "Max Sqft" }
                                options={parseInt(selectedMinSqftValue) === 0 ? sqftmaxRange : maxSqft}
                                value={sqftmaxRange.find(obj => obj.value === selectedMaxSqftValue) || sqftmaxRange.find(obj => obj.value === maxsqft1)}
                                onChange={e => {onChangeMaxSqft(e);setMaxSqFt1(e.value)}}
                                classNamePrefix={"select-opt"}
                                isSearchable={false}
                                components={{ DropdownIndicator:() => <i className="icon icon-dropdown-arrow"></i>, IndicatorSeparator:() => null }}
                            />
                        </div>
                    </div>
                </Form.Group>

                <a href="javascript:void(0)" onClick={submitSearch} className="btn submitSearch d-flex align-items-center">Submit</a>
            </Form>
        </>
    )
}

export default SearchResultsFilterCommRentals